@import '../../styles/variables.scss';

.AdmitToggle {  
  &-label {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 2px solid $colour-mid-green;
    border-radius: 4px;
    transition: all 0.3s ease;
    
    &:hover {
      background-color: $colour-white;
    }
    
    &.isAdmitted {
      color: #4CAF50;
      border-color: $colour-primary;

      &::after {
        font-size: 24px;
        top: 30%;
        left: 70%;
      }
    }
    
    &::after {
      content: '✔️';
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 0px;
      color: $colour-mid-green;
      transition: all 0.3s ease;
    }
  }

  &-checkbox {
    visibility: hidden;
  }
  
  &-error {
    color: $colour-red;
    margin-top: $spacing-unit;
  }

  .Loading {
    width: 20px;
  }

  &-text {
    margin-left: $padding * 2;
    width: 250px;
    position: absolute;
  }
}
