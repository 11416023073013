@import "../variables";

.Button {
    padding: calc($padding / 2) $padding;
    display: block;
    font-size: 16px;
    width: 100%;
    background-color: $colour-primary;
    border-radius: $border-radius;
    color: $colour-white;
    border: none;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    margin-top: $padding;
    cursor: pointer;

    &.isInactive {
        background-color: desaturate(lighten($colour-primary, 25%), 40%);
    }
}
