@import "../../styles/variables.scss";
@import "../../styles/cssComponents/Button.scss";

.Allocations {
  &-loading {
    max-width: 60px;
    margin: $padding*2 auto;
  }

  &-event {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-name {
      font-size: $font-size;
      text-align: left;

      &-link {
        color: $colour-primary;
        text-decoration: none;
      }
    }
  }
}
