@import "../../styles/variables";

.ButtonGroup {
    border-radius: $border-radius * 2;
    border: 1px solid $colour-primary;
    width: auto;
    display: inline-block;
    padding: 3px; // $unit/6
    margin-right: $padding;

    &:last-child {
        margin-right: 0;
    }

    .Button {
        display: inline-block;
        width: auto;
        margin: 0 1px 0 0;
        border-radius: 0;

        &:first-child {
            border-radius: $border-radius 0 0 $border-radius;
        }

        &:last-child {
            border-radius: 0 $border-radius $border-radius 0;
            margin-right: 0;
        }
    }
}
