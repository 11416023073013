@import "styles/variables";

.App {
  text-align: center;

  &-header {
    display: flex;
    justify-content: space-between;
    text-align: left;
    background-color: $colour-light-bg;
    height: 40px;
    padding: $padding;
    color: $colour-black;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),
      0 4px 5px 0 rgba(0,0,0,.14),
      0 1px 10px 0 rgba(0,0,0,.12);

    @media (max-width: 720px) {
      flex-direction: column;
      row-gap: 6px;
      padding-top: calc($padding / 2);
      height: 70px;
    }
  }

  &-logo {
    height: 40px;
  }

  &-body {
    padding: $padding;
  }
}
