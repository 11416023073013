@import '../../styles/variables.scss';
@import "../../styles/cssComponents/Button.scss";

.Scan {
  &-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0 auto;
    max-width: 600px;
    padding: 20px;
    text-align: left;
    gap: $padding;
  }

  &-input {
      margin-right: 10px;
      padding: 0 10px;
      width: 100%;
  }

  &-submit {
    &.Button {
      width: 100px;
    }
  }

  #video {
    position: static;
  }
}
