@import "../../styles/variables.scss";

.TicketInfo {
  position: fixed;
  top: $padding;
  left: $padding;
  right: $padding;
  bottom: $padding;
  background: $colour-white;
  padding: $padding;
  border-radius: $border-radius;
  box-shadow: 0 0 10px rgba($colour-black, 0.2);
  border: 4px solid $colour-primary;
  display: flex;
  gap: $padding;
  flex-direction: column;
  text-align: left;

  &-code {
    font-family: monospace;
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }

  &-quantity {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }

  &-close {
    position: absolute;
    top: $padding;
    right: $padding;
    font-size: 0;
    font-weight: 400;
    color: $colour-black;
    cursor: pointer;

    &::after {
      content: "×";
      font-size: 2rem;
      font-weight: 400;
      display: block;
    }
  }

  &-admitToggle {
    margin-bottom: $padding;
  }
}
