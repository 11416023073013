@import '../../styles/variables.scss';

.NavBar {
  display: flex;
  align-items: center;
  column-gap: $padding;

  @media (max-width: 720px) {
    justify-content: center;
    row-gap: $padding;
  }

  &-item {
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    color: $colour-black;
  }

  .Button {
    margin-top: 0;
    max-width: 120px;
    color: $colour-white;
  }
}
