@import "../../styles/variables";

.AllocationList {
  &-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &-filters {
    margin-top: $padding;
    padding-top: $padding;
    border-top: 1px solid $colour-mid-green;
  }

  &-listItem {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr auto auto;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #eee;

    @media (max-width: 480px) {
      grid-template-columns: 1fr 1fr;
      padding: 0.5rem;
    }

    h3, h4, p {
      margin: 0;
      padding: 0;
      display: inline-block;
    }
  }

  &-name {
    text-align: left;
    font-size: 16px;
    font-weight: normal;
  }

  &-code {
    text-align: left;
    font-family: monospace;

    @media (max-width: 480px) {
      text-align: right;
    }
  }

  &-eventName,
  &-admitted {
    text-align: left;
  }

  &-admitToggle {
    text-align: right;
    font-size: 0;
    justify-self: end;

    .Loading {
      width: 20px;
    }
  }

}
