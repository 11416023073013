$colour-white: #fff;
$colour-light-bg: #d6e2e4;
$colour-mid-green: #99b6bb;
$colour-primary: #2c98b1;
$colour-black: #252d2f;
$colour-red: #ed5c3a;

$padding: 20px;
$font-size: 16px;
$border-radius: 3px;
$spacing-unit: 8px;
