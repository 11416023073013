@import "../variables";

.Input {
  display: block;
  margin: $padding auto;
  width: 100%;
  text-align: left;

  &-field {
    display: block;
    width: 100%;
    padding: calc($padding / 2);
    border: 1px solid $colour-mid-green;
    box-sizing: border-box;
    font-size: $font-size;
    border-radius: $border-radius;
  }
}
